import { useContext } from 'react';
import { WizardContext } from './context';
/**
 * Hook for handling Wizard component
*/
function useWizard() {
    const context = useContext(WizardContext);
    if (!context) {
        throw new Error('wizardContext not found!');
    }
    return context;
}
export default useWizard;
