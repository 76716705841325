import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { PaymentSystemsUtil } from '@shared/ui/misc/CardSelect';
import IconText from '@shared/ui/icons/IconText';
import { AlertIcon, CheckedIcon, EditIcon } from './icons';
const IconTextStyled = styled(IconText)(props => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: props.selected ? 0 : '22px',
    color: props.selected ? props.theme.palette.primary.main : props.theme.palette.text.secondary,
    width: '100%',
}));
const PaymentErrorContainer = styled.span(props => ({
    display: 'inline-flex',
    alignItems: 'center',
    color: props.theme.palette.error.main,
    gap: props.theme.spacing_sizes.s,
    marginLeft: props.theme.spacing_sizes.m,
}));
const PaymentErrorLabel = ({ text }) => (_jsxs(PaymentErrorContainer, { children: [_jsx(AlertIcon, {}), text] }));
const SelectOption = ({ option, isSelected, onEdit, paymentErrorText, }) => {
    const { t } = useTranslation();
    const getPaymentSystemIcon = (type) => {
        var _a, _b;
        return (type ? (_b = (_a = PaymentSystemsUtil
            .getPaymentSystemsForSelect(t)) === null || _a === void 0 ? void 0 : _a[type]) === null || _b === void 0 ? void 0 : _b.mainIcon : () => undefined);
    };
    const handleEdit = () => {
        onEdit(option);
    };
    return (_jsxs(_Fragment, { children: [isSelected ? _jsx(CheckedIcon, {}) : null, _jsx(IconTextStyled, { IconComponent: getPaymentSystemIcon(option === null || option === void 0 ? void 0 : option.paymentSystem)(), text: (_jsxs(_Fragment, { children: [option.label, option.hasError ? _jsx(PaymentErrorLabel, { text: paymentErrorText }) : null] })), selected: isSelected }), _jsx(EditIcon, { onClick: handleEdit })] }));
};
export default SelectOption;
