import { jsx as _jsx } from "react/jsx-runtime";
import { Children } from 'react';
import { WizardProvider } from './context';
import useWizard from './useWizard';
const WizardInner = ({ children, }) => {
    const { current } = useWizard();
    return Children
        .toArray(children)
        .filter((_ch, index) => current === index);
};
/**
 * Component to handle multi-step forms
 *
 */
export const Wizard = ({ initialStep = 0, children, useExternalState = undefined, }) => (_jsx(WizardProvider, { initialStep: initialStep, maxSteps: Children.count(children), useExternalState: useExternalState, children: _jsx(WizardInner, { children: Array.isArray(children) ?
            children.map((child) => (child)) :
            children }) }));
