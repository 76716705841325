import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from '@emotion/styled';
const VirtualListContainer = styled.div(() => ({
    '&::-webkit-scrollbar': {
        borderRadius: 4,
        width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#C1C8CD',
        width: 4,
        borderRadius: 4,
    },
}));
const VirtualItemContainer = styled.div();
const ContainerInner = styled.div();
const VirtualListComponent = ({ children = [], itemSizes, listHeight, }, ref) => {
    const virtualizer = useVirtualizer({
        count: children.length,
        getScrollElement: () => ref === null || ref === void 0 ? void 0 : ref.current,
        estimateSize: (i) => itemSizes[i],
        gap: 5,
        enabled: true,
        overscan: 2,
    });
    return (_jsx(VirtualListContainer, { style: {
            height: listHeight,
            width: '100%',
            position: 'relative',
            overflowY: 'auto',
        }, ref: ref, children: _jsx(ContainerInner, { style: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: 'translateY(0px)',
            }, children: virtualizer.getVirtualItems().map((virtualColumn) => (_jsx(VirtualItemContainer, { "data-index": virtualColumn.index, ref: virtualColumn.measureElement, style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualColumn.start}px)`,
                }, children: children[virtualColumn.index] || null }, virtualColumn.key))) }) }));
};
const VirtualList = forwardRef(VirtualListComponent);
export default VirtualList;
