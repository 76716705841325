import styled from '@emotion/styled';
import { ReactComponent as VisaIconComponent } from '@icons/payment-systems/visa.svg';
import { ReactComponent as PaypalIconComponent } from '@icons/payment-systems/paypal.svg';
import { ReactComponent as MastercardIconComponent } from '@icons/payment-systems/mastercard.svg';
import { ReactComponent as PlusIconComponent } from '@icons/wolfkit-light/plus-light.svg';
import { ReactComponent as PencilIcon } from '@icons/wolfkit-light/pencil-light.svg';
import { ReactComponent as CheckedIconComponent } from '@icons/wolfkit-light/checkmark-light.svg';
import { ReactComponent as AlertIconComponent } from '@icons/wolfkit-light/alert-circle-light.svg';
const VisaIcon = styled(VisaIconComponent)(props => ({
    width: props.theme.baseSize * 7,
    height: props.theme.baseSize * 4,
    rect: {
        fill: 'transparent',
    },
    path: {
        fill: '#D6DCE0',
    },
}));
const PaypalIcon = styled(PaypalIconComponent)(props => ({
    width: props.theme.baseSize * 7,
    height: props.theme.baseSize * 4,
    rect: {
        fill: 'transparent',
    },
    path: {
        fill: '#D6DCE0',
    },
}));
const MastercardIcon = styled(MastercardIconComponent)(props => ({
    width: props.theme.baseSize * 7,
    rect: {
        fill: 'transparent',
    },
    path: {
        fill: '#D6DCE0',
    },
    '& :last-child': {
        stroke: props.theme.palette.text.disabled,
    },
}));
const CheckedIcon = styled(CheckedIconComponent)(props => ({
    marginRight: props.theme.spacing_sizes.m,
    width: props.theme.baseSize * 2,
    height: props.theme.baseSize * 2,
}));
const PlusIcon = styled(PlusIconComponent)(props => ({
    path: {
        fill: props.theme.palette.primary.main,
    },
}));
const EditIcon = styled(PencilIcon)(props => ({
    path: {
        fill: props.theme.palette.primary.main,
        // trick to get mirrored icon
        transform: 'translate(24px, 0px) scale(-1, 1)',
    },
}));
const AlertIcon = styled(AlertIconComponent)(props => ({
    width: 16,
    height: 16,
    path: {
        fill: props.theme.palette.error.main,
    },
}));
export { MastercardIcon, PaypalIcon, VisaIcon, CheckedIcon, PlusIcon, EditIcon, AlertIcon, };
