import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
const HiddenImage = styled.img(() => ({
    visibility: 'hidden',
    width: 0,
    height: 0,
}));
const LazyImgLoader = ({ url = undefined, children = undefined, onLoadError = undefined, onLoadSuccess = undefined, }) => {
    const [loadingState, setLoadingState] = useState('loading');
    const handleLoad = () => {
        setLoadingState('success');
        if (onLoadSuccess) {
            onLoadSuccess();
        }
    };
    const handleLoadError = () => {
        setLoadingState('error');
        if (onLoadError) {
            onLoadError();
        }
    };
    useEffect(() => {
        setLoadingState('loading');
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(HiddenImage, { onLoad: handleLoad, onError: handleLoadError, src: url, loading: 'lazy' }), loadingState === 'success' && children] }));
};
export default LazyImgLoader;
