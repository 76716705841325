import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, } from 'react';
export const WizardContext = createContext(null);
// Hook with inner logic for handling WizardProvider
export function useWizardState(maxSteps, initialStep = 0, onChange) {
    const [current, setCurrent] = useState(initialStep);
    function goTo(step) {
        if (step > current) {
            setCurrent(Math.min(maxSteps - 1, step));
            if (onChange) {
                onChange();
            }
        }
        if (step < current) {
            setCurrent(Math.max(0, step));
        }
    }
    function goNext() {
        goTo(current + 1);
    }
    function goBack() {
        goTo(current - 1);
    }
    function reset() {
        setCurrent(initialStep);
    }
    return {
        current, goNext, goBack, goTo, reset, count: maxSteps,
    };
}
export const WizardProvider = ({ children, initialStep, maxSteps, onChange = () => { }, useExternalState = undefined, }) => {
    const internalState = useWizardState(maxSteps, initialStep, onChange);
    // TODO: if we leave it like that, in case of using external state,
    // we will have unused internalState in memory
    const state = useExternalState || internalState;
    return (_jsx(WizardContext.Provider, { value: state, children: children }));
};
